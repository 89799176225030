<template>
    <v-card>
      <!-- <v-card-title primary-title>
        Gráficos
      </v-card-title> -->

      <v-container>
        <v-row>
          <v-col cols="12" sm="12" md="6">
            <v-autocomplete
              v-model="policy"
              :items="policies"
              item-text="name"
              item-value="id"
              label="Escoge una Póliza"
              @change="getData"
              clearable
              rounded
              solo
              hide-details
            >
            
            <template v-slot:selection="data">
              <v-avatar
                :color="data.item.color"
                size="8"
                class="ma-1"
              >
                <span class="white--text headline"></span>
              </v-avatar>
              <v-chip> {{ data.item.name }} </v-chip>
            </template>
            <template v-slot:item="data">
              <template>
                <v-list-item-avatar
                  :color="data.item.color"
                  size="8"
                  class="mr-2"
                >
                  <span class="white--text headline"></span>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{
                    data.item.name
                  }}</v-list-item-title>
                </v-list-item-content>
              </template>
            </template>
            
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-container>

      <v-card-text>
        
      <v-row>
        <!-- NEW VERSION -->
        <v-col cols="12" sm="12" md="12">
          <v-card color="rgba(0, 0, 0, 0.1)">
            <v-card-title class="text-h5 font-weight-bold" style="color:#385f73;">
              Datos Generales
            </v-card-title>
            <v-divider class="my-2"></v-divider>
            
            <v-card-text style="color:black">

              <v-row>
                <v-col cols="12" sm="6" md="3">
                  <span class="d-block font-weight-bold"
                    >Código del Cliente</span
                  >
                  {{ data.client_code }}
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <span class="d-block font-weight-bold"
                    >Nombres</span
                  >
                  {{ data.name }}
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <span class="d-block font-weight-bold"
                    >Apellidos</span
                  >
                  {{ data.surname }}
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <span class="d-block font-weight-bold"
                    >Fecha de primer pago</span
                  >
                  {{ dateFormat2(data.payment_date) }}
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <span class="d-block font-weight-bold"
                    >Vigencia (Desde)</span
                  >
                  {{ dateFormat2(data.validity_date1) }}
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <span class="d-block font-weight-bold"
                    >Vigencia (Hasta)</span
                  >
                  {{ dateFormat2(data.validity_date2) }}
                </v-col>
                
                <v-col cols="12" sm="6" md="3">
                  <span class="d-block font-weight-bold"
                    >Aseguradora</span
                  >
                  {{ data.insurance }}
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <span class="d-block font-weight-bold"
                    >Banco</span
                  >
                  {{ data.bank }}
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <span class="d-block font-weight-bold"
                    >Prima Neta</span
                  >
                  {{ data.initial_value }}
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <span class="d-block font-weight-bold"
                    >Prima Total</span
                  >
                  {{ data.total_value }}
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <span class="d-block font-weight-bold"
                    >Gastos de Emisión</span
                  >
                  {{ data.emision_cost }}
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <span class="d-block font-weight-bold"
                    >Moneda</span
                  >
                  {{ data.type_currency }}
                </v-col>
                
                <v-col cols="12" sm="6" md="3">
                  <span class="d-block font-weight-bold"
                    >Número de Pagos</span
                  >
                  {{ data.numbers_pays }}
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <span class="d-block font-weight-bold"
                    >Suma Asegurada</span
                  >
                  {{ data.amount_insured }}
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <span class="d-block font-weight-bold"
                    >Ramo</span
                  >
                  {{ data.ramo }}
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <span class="d-block font-weight-bold"
                    >Tipo</span
                  >
                  {{ data.branch }}
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions>
              <v-btn text>
              </v-btn>
            </v-card-actions>

          </v-card>
        </v-col>


        <v-col cols="12" sm="12" md="6" v-if="data.id">
          <v-card color="rgba(0, 0, 0, 0.1)">
            <v-card-title class="text-h5 font-weight-bold" style="color:rgb(31, 112, 135);">
              Documentos
            </v-card-title>

            <v-divider class="my-2"></v-divider>
             <v-card-text class="text--primary">
              <div
                    v-if="data.filename"
                    style="font-size:13px"
                    class="ml-2 ma-1"
                  >

                  <v-chip
                    :color=" $vuetify.theme.dark ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0.1)' "
                    style="display:inline-block;"
                    @click="seeDocument(data.path)"
                    dark
                    :text-color=" $vuetify.theme.dark ? 'white' : 'black' "
                    >{{ data.filename }}
                  </v-chip>
              </div>
              <div
                    v-if="data.filename_request"
                    style="font-size:13px"
                    class="ml-2 ma-1"
                  >

                  <v-chip
                    :color=" $vuetify.theme.dark ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0.1)' "
                    style="display:inline-block;"
                    @click="seeDocument(data.path_request)"
                    dark
                    :text-color=" $vuetify.theme.dark ? 'white' : 'black' "
                    >{{ data.filename_request }}
                  </v-chip>
              </div>
              <div
                    v-if="data.filename_policy"
                    style="font-size:13px"
                    class="ml-2 ma-1"
                  >

                  <v-chip
                    :color=" $vuetify.theme.dark ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0.1)' "
                    style="display:inline-block;"
                    @click="seeDocument(data.path_policy)"
                    dark
                    :text-color=" $vuetify.theme.dark ? 'white' : 'black' "
                    >{{ data.filename_policy }}
                  </v-chip>
              </div>
              <div
                    v-if="data.filename_renew"
                    style="font-size:13px"
                    class="ml-2 ma-1"
                  >

                  <v-chip
                    :color=" $vuetify.theme.dark ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0.1)' "
                    style="display:inline-block;"
                    @click="seeDocument(data.path_renew)"
                    dark
                    :text-color=" $vuetify.theme.dark ? 'white' : 'black' "
                    >{{ data.filename_renew }}
                  </v-chip>
              </div>
            </v-card-text>
          </v-card>
        </v-col>


        <v-col cols="12" sm="12" md="6" v-if="insureds.length">
          <v-card color="rgba(0, 0, 0, 0.1)">
             <v-card-title class="text-h5 font-weight-bold" style="color:rgb(31, 112, 135);">
              Asegurados
            </v-card-title>
            <v-divider class="my-2"></v-divider>

            <v-card-text>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        Certificado
                      </th>
                      <th class="text-left">
                        Identidad
                      </th>
                      <th class="text-left">
                        Nombre
                      </th>
                      <th class="text-left">
                        Dependientes
                      </th>
                      <th class="text-left">
                        Fecha de Alta
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, key) in insureds"
                      :key="key"
                    >
                      <td>{{ item.certificate_number }}</td>
                      <td>{{ item.identity }}</td>
                      <td>{{ item.name }}</td>
                      <td>{{ item.dependents }}</td>
                      <td>{{ dateFormat2(item.date) }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>

          </v-card>
        </v-col>

        <!-- Dependientes -->
        <v-col cols="12" sm="12" md="6" v-if="dependents.length">
          <v-card color="rgba(0, 0, 0, 0.1)">
             <v-card-title class="text-h5 font-weight-bold" style="color:rgb(31, 112, 135);">
              Dependientes
            </v-card-title>
            <v-divider class="my-2"></v-divider>

            <v-card-text>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        Asegurado
                      </th>
                      <th class="text-left">
                        Nombre
                      </th>
                      <th class="text-left">
                        Parentezco
                      </th>
                      <th class="text-left">
                        Fecha de Nacimiento
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, key) in dependents"
                      :key="key"
                    >
                      <td>{{ item.policyholder }}</td>
                      <td>{{ item.name }}</td>
                      <td>{{ item.kinship }}</td>
                      <td>{{ dateFormat2(item.birthday) }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>

          </v-card>
        </v-col>

        <!-- AUTOS -->
        <v-col cols="12" sm="12" md="6" v-if="cars.length">
          <v-card color="rgba(0, 0, 0, 0.1)">
             <v-card-title class="text-h5 font-weight-bold" style="color:rgb(31, 112, 135);">
              Autos
            </v-card-title>
            <v-divider class="my-2"></v-divider>

            <v-card-text>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        Marca
                      </th>
                      <th class="text-left">
                        Modelo
                      </th>
                      <th class="text-left">
                        Color
                      </th>
                      <th class="text-left">
                        Motor
                      </th>
                      <th class="text-left">
                        Año
                      </th>
                      <th class="text-left">
                        Placa
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, key) in cars"
                      :key="key"
                    >
                      <td>{{ item.brand }}</td>
                      <td>{{ item.model }}</td>
                      <td>{{ item.color }}</td>
                      <td>{{ item.engine }}</td>
                      <td>{{ item.year }}</td>
                      <td>{{ item.plate }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>

          </v-card>
        </v-col>

        <v-col cols="12" sm="12" md="6" v-if="increments.length">
          <v-card color="rgba(0, 0, 0, 0.1)">
             <v-card-title class="text-h5 font-weight-bold" style="color:rgb(31, 112, 135)">
              Incrementos
            </v-card-title>
            <v-divider class="my-2"></v-divider>

            <v-card-text>
              <div
                  v-for="(element, key) in increments"
                  :key="key"
                  class="ml-2 ma-1"
                >
                
                <v-chip
                  color="rgba(0, 0, 0, 0.1)"
                  style="display:inline-block;"
                  class="mr-2"
                  >
                  {{ element.date }} 
                </v-chip>

                <v-chip
                  color="rgba(0, 0, 0, 0.1)"
                  style="display:inline-block;"
                  class="mr-2"
                  >
                  {{ element.amount_insured }} 
                </v-chip>
                
                <v-icon
                @click="seeDocument(element.path)"
                color="purple darken-1"
                >fas fa-file-pdf</v-icon>

              </div>
            </v-card-text>

          </v-card>
        </v-col>

        <v-col cols="12" sm="12" md="6" v-if="renovations.length">
          <v-card color="rgba(0, 0, 0, 0.1)">
             <v-card-title class="text-h5 font-weight-bold" style="color:rgb(31, 112, 135)">
              Renovaciones
            </v-card-title>
            <v-divider class="my-2"></v-divider>

            <v-card-text>
              <div
                  v-for="(element, key) in renovations"
                  :key="key"
                  class="ml-2 ma-1"
                >

                <v-chip
                  color="rgba(0, 0, 0, 0.1)"
                  style="display:inline-block;"
                  class="mr-2"
                  >
                  {{ validityFormat(element.validity_date1, element.validity_date2) }}
                </v-chip>
                
                <v-icon
                @click="seeDocument(element.path)"
                color="purple darken-1"
                >fas fa-file-pdf</v-icon>

              </div>
            </v-card-text>

          </v-card>
        </v-col>

        <v-col cols="12" sm="12" md="6" v-if="properties.length">
          <v-card color="rgba(0, 0, 0, 0.1)">
             <v-card-title class="text-h5 font-weight-bold" style="color:rgb(31, 112, 135)">
              Bienes
            </v-card-title>
            <v-divider class="my-2"></v-divider>

            <v-card-text>
              <div
                  v-for="(element, key) in properties"
                  :key="key"
                  class="ml-2 ma-1"
                >
                <v-chip
                  color="rgba(0, 0, 0, 0.1)"
                  style="display:inline-block;"
                  class="mr-2"
                  >
                  {{ element.property }} 
                </v-chip>

                <v-chip
                  color="rgba(0, 0, 0, 0.1)"
                  style="display:inline-block;"
                  class="mr-2"
                  >
                  {{ element.amount_insured }} 
                </v-chip>
                
                {{ element.location }}

              </div>
            </v-card-text>

          </v-card>
        </v-col>
      </v-row>
      </v-card-text>
    </v-card>

</template>

<script>
  import { mapActions, mapState } from "vuex";
  import axios from "axios";
  import * as moment from 'moment';

  export default {
    name: "reportPolicy",
    data: () => ({
      titulo: "Incrementos",
      policy: "",
      policies: [],
      insureds: [],
      cars: [],
      increments: [],
      dependents: [],
      properties: [],
      renovations: [],
      data: {},
      ramos: [
      { id: 1, name: "Vidas" },
      { id: 2, name: "Daños" },
      { id: 3, name: "Fianzas" },
      ],
      coins: [
        { id: 1, name: "Lempiras" },
        { id: 2, name: "Dólares" },
      ],
    }),
    
    methods: {
      ...mapActions(["cerrarSesion", "validateSession"]),

      async getData(){
        console.log(this.policy);
        this.data = {};
        this.insureds = [];
        this.cars = [];
        this.increments = [];
        this.properties = [];
        this.dependents = [];
        this.renovations = [];
        if (!this.policy > 0) {
          return;
        }
        try {
          await axios.get(this.db + 'policy_report/' + this.policy,
            {
              headers: this.headers_db
            }).then(response => {
              this.data = response.data.data[0];
              
              
                if (this.data.id_ramo > 0) {
                  this.data.ramo = this.ramos.filter(doc => {
                    return doc.id == this.data.id_ramo
                  })[0].name;
                }
                if (this.data.type_currency > 0) {
                  this.data.type_currency = this.coins.filter(doc => {
                    return doc.id == this.data.type_currency
                  })[0].name;
                }
              

              this.loading = false;
              console.log(this.data);
            })
            .catch((error) => {
              console.log(error);
              this.validateSession(error).then((resp) => { if(resp) this.getData(); })
            });

            await axios.get(this.db + 'insureds/' + this.policy,
            {
              headers: this.headers_db
            }).then(response => {
              this.insureds = response.data.data;
            }).catch(console.log);

            await axios.get(this.db + 'cars/' + this.policy,
            {
              headers: this.headers_db
            }).then(response => {
              this.cars = response.data.data;
            }).catch(console.log);

            await axios.get(this.db + 'increment/policy/' + this.policy,
            {
              headers: this.headers_db
            }).then(response => {
              this.increments = response.data.data;
            }).catch(console.log);

            await axios.get(this.db + 'properties/policy/' + this.policy,
            {
              headers: this.headers_db
            }).then(response => {
              this.properties = response.data.data;
            }).catch(console.log);

            await axios.get(this.db + 'dependents/policy/' + this.policy,
            {
              headers: this.headers_db
            }).then(response => {
              this.dependents = response.data.data;
            }).catch(console.log);

            await axios.get(this.db + 'renovation/policy/' + this.policy,
            {
              headers: this.headers_db
            }).then(response => {
              this.renovations = response.data.data;
            }).catch(console.log);

        } catch (e) {
          console.log(e);
        }
      },

      async getPolicies(){
      
        await axios.get(this.db + 'policy_report/policies',
        {
          headers: this.headers_db
        }).then(response => {
          this.policies = response.data.data;
          this.policies.forEach(element => {
            console.log(element.active);
            if (element.active == 1) {
              element.color = "green";
            }
            else {
              element.color = "grey";
            }
          });
          this.loading = false;
          console.log(response.data.data);
        })
        .catch((error) => {
          console.log(error);
          this.validateSession(error).then((resp) => { if(resp) this.getPolicies(); })
        });
      },

    async seeDocument(path){
      console.log(path);
      await axios.get(this.db + 'policy/download/' + path,
      {
        responseType: 'arraybuffer',
        headers: {
            // 'Content-Type': 'multipart/form-data',
            "Content-type": "application/x-www-form-urlencoded",
            'Authorization': 'BEARER ' + localStorage.token
        }
      })
      .then(response => {
          console.log(response );
          let blob = new Blob([response.data], { type: 'application/pdf' } ),
          url = window.URL.createObjectURL(blob)

          window.open(url);
      })
      .catch(error => {
          console.log("Fallo", error );
          if (error.response.status == 401) {
            this.getCustomers();
          }
      });
    },

    dateFormat2(date) {
      return moment(date).format('DD/MM/YYYY');
    },

    validityFormat(date1, date2) {
      date1 = moment(date1).format('DD-MM-YYYY');
      date2 = moment(date2).format('DD-MM-YYYY');
      return date1 + ' ~ ' + date2;
    },

    },
    computed: {
      ...mapState(["db","headers_db"]),
      cargando() {
        return this.carga ? "loading" : "false";
      }
    },

    mounted () {
        this.getPolicies();
    },
  }
</script>

<style>
  .small {
    max-height: 350px;
    /* margin:  150px auto; */
  }
</style>